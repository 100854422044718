<template>
  <v-form v-model="valid" ref="form">
    <v-menu
      class="pr-2"
      ref="retornoDateInicio"
      lazy
      :close-on-content-click="false"
      v-model="dataRetornoInicio"
      transition="scale-transition"
      offset-y
      full-width
      :nudge-bottom="-22"
      max-width="290px"
      :return-value.sync="dataRetornoEntradaInicio"
    >
      <v-text-field
        slot="activator"
        label="Data início retorno"
        v-model="dataRetornoEntradaInicio"
        append-icon="event"
        readonly
      ></v-text-field>
      <v-date-picker v-model="dataRetornoEntradaInicio" no-title scrollable>
        <v-spacer></v-spacer>
        <v-btn flat color="primary" @click="dataRetornoInicio = false">Cancel</v-btn>
        <v-btn flat color="primary" @click="$refs.retornoDateInicio.save(dataRetornoEntradaInicio)">OK</v-btn>
      </v-date-picker>
    </v-menu>
    <v-menu
      class="pr-2"
      ref="retornoDateFim"
      lazy
      :close-on-content-click="false"
      v-model="dataRetornoFinal"
      transition="scale-transition"
      offset-y
      full-width
      :nudge-bottom="-22"
      max-width="290px"
      :return-value.sync="dataRetornoEntradaFinal"
    >
      <v-text-field
        slot="activator"
        label="Data fim retorno"
        v-model="dataRetornoEntradaFinal"
        append-icon="event"
        readonly
      ></v-text-field>
      <v-date-picker v-model="dataRetornoEntradaFinal" no-title scrollable>
        <v-spacer></v-spacer>
        <v-btn flat color="primary" @click="dataRetornoFinal = false">Cancel</v-btn>
        <v-btn flat color="primary" @click="$refs.retornoDateFim.save(dataRetornoEntradaFinal)">OK</v-btn>
      </v-date-picker>
    </v-menu>
    <div class="form-btn">
      <v-btn outline @click="gerarRelatorio" color="primary">Gerar relatório</v-btn>
      <v-btn @click="voltar" outline>Voltar</v-btn>
    </div>
    <v-snackbar :color="'pink accent-1'" v-model="snackbar" :top="true">
      {{ text }}
    </v-snackbar>
  </v-form>
</template>
<script>
import { RelatoriosSaida } from "../../../servicos/relatoriosSaida"
const servicoRelatoriosSaida = new RelatoriosSaida()
export default {
  data() {
    return {
      snackbar: false,
      text: "Gerando arquivo para impressão. Aguarde!",
      dataRetornoInicio: false,
      dataRetornoEntradaInicio: null,
      dataRetornoFinal: false,
      dataRetornoEntradaFinal: null
    }
  },
  methods: {
    gerarRelatorio() {
      if (this.$refs.form.validate()) {
        this.text = "Gerando arquivo para impressão. Aguarde!"
        this.snackbar = true

        servicoRelatoriosSaida.imprimirVendasPorLinha(this.dataRetornoEntradaInicio, this.dataRetornoEntradaFinal).then(
          response => {
            var blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
            var link = document.createElement("a")
            link.href = window.URL.createObjectURL(blob)
            var fileName = `${this.dataRetornoEntradaInicio}_${this.dataRetornoEntradaFinal}_vendas_por_linha.xlsx`
            link.download = fileName
            link.click()
            this.snackbar = false
          },
          err => {
            this.snackbar = true
            this.text = err.response.data[0].detail
          }
        )
      }
    },
    voltar() {
      this.$router.push({ name: "Relatorios" })
    }
  }
}
</script>
