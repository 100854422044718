<template>
  <div>
    <v-container grid-list-x2 fluid>
      <v-layout row wrap justify-center>
        <v-flex lg6 sm12 xs12>
          <v-card>
            <v-toolbar color="pink" dark flat dense cad>
              <v-toolbar-title class="subheading">Relatório de vendas por período - agrupado por linha</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="">
              <filtro></filtro>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import Filtro from "@/components/relatorios/saidas/vendasPorPeriodo"
export default {
  components: {
    Filtro
  }
}
</script>
